export function exportToCsv(data, filename) {
  if (!data.length) {
    return;
  }

  const csvContent = [
    Object.keys(data[0]).join(','), // Header row
    ...data.map((row) => Object.values(row).map((value) => `"${value}"`).join(',')),
  ].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
